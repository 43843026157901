import { graphql, useStaticQuery } from 'gatsby'

import { StrapiPrivacypolicy } from '~/types/graphql'

const usePrivacyPolicyStaticQuery = () => {
  const query = useStaticQuery<StrapiPrivacypolicy>(graphql`
    query PrivacyPolicyContent {
      strapiPrivacypolicy {
        body {
          data {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  `)
  return query
}

export default usePrivacyPolicyStaticQuery
