import MainLayout from '~/components/layouts/MainLayout'
import { WithRequired } from '~/types/helper'

import * as containerStyles from './PrivacyPolicy.module.scss'
import usePrivacyPolicyStaticQuery from './usePrivacyPolicyStaticQuery'

const PrivacyPolicy = () => {
  const query = usePrivacyPolicyStaticQuery()

  const { body } = query.strapiPrivacypolicy as WithRequired<
    Queries.STRAPI_PRIVACYPOLICY,
    'body'
  >

  return (
    <MainLayout isProgress isStaticChat scrollTop>
      <div className={containerStyles.privacyPolicyPage}>
        <div className="container container-md">
          <div className="row">
            <div className="col-12">
              <div className={containerStyles.privacyPolicyPage__content}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: body?.data?.childMarkdownRemark?.html,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default PrivacyPolicy
