import { graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'

import SEO from '~/components/shared/Seo'
import { QueryResult, StrapiPrivacypolicy } from '~/types/graphql'
import { fileToImageLikeData, getPageMeta } from '~/utils'
import PrivacyPolicy from '~/views/PrivacyPolicy/PrivacyPolicy.view'

const PrivacyPolicyPage = () => <PrivacyPolicy />

export const query = graphql`
  query {
    strapiPrivacypolicy {
      meta_title
      meta_description
      og_image {
        localFile {
          childImageSharp {
            gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
          }
        }
      }
    }
  }
`

export default PrivacyPolicyPage

export const Head = ({ data }: QueryResult<StrapiPrivacypolicy>) => {
  const { meta_title, meta_description, og_image } = getPageMeta(
    data.strapiPrivacypolicy,
  )

  return (
    <SEO
      title={meta_title}
      description={meta_description}
      urlImage={getSrc(fileToImageLikeData(og_image.localFile)) as string}
      url="https://www.codica.com/privacy-policy/"
    />
  )
}
